import { LoadingContext } from '@contexts/loading';
import { useContext } from 'react';
import Lottie from 'lottie-react';
import animationData from 'public/lotties/loading.json';

const Preloader = (props: any) => {
  const { isLoadingOn } = useContext(LoadingContext);

  return isLoadingOn ? (
    <>
      <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-[1100]">
        <div className="flex justify-center items-center w-full h-full">
          <div className="flex flex-col justify-center items-center bg-white p-8 rounded-lg w-[220px] h-[220px]">
            <Lottie animationData={animationData} loop={true} />
            <p className="font-semibold ls-loose text-gray-500">Loading ...</p>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Preloader;
